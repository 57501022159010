import React, { useEffect } from "react";

const About = () => {

    // change range value ----------------------------
    useEffect(() => {
        let skills = document.querySelectorAll(".skills .item");

        skills.forEach((skil) => {
            let number = skil.querySelector(".number");

            number.style.right = number.innerHTML;
        });
    }, []);
    // ------------------x-------------x----------------

    return (
        <section className="about-cont pd animate-left link">
            <div className="about">
                <div className="title">
                    <h2>من نحن</h2>
                </div>
                <div className="about-info">
                    <div className="content">
                        <div className="row">
                            <div className="img col-md-6">
                                <div className="img-content">
                                    <img
                                        src="assets/img/about-img.png"
                                        alt="about-img"
                                    />
                                </div>
                            </div>
                            <div className="info col-md-6">
                                <div className="top">
                                    <h4>
                                        <span>اهلا</span> بك
                                    </h4>
                                </div>
                                <div className="desc">
                                    <p>
                                        هذا النص هو مثال لنص يمكن أن يستبدل في
                                        نفس المساحة، لقد تم توليد هذا النص من
                                        مولد النص العربى، حيث يمكنك أن تولد مثل
                                        هذا النص أو العديد من النصوص الأخرى
                                        إضافة إلى زيادة عدد الحروف التى يولدها
                                        التطبيق.إذا كنت تحتاج إلى عدد أكبر من
                                        الفقرات يتيح لك مولد النص العربى زيادة
                                        عدد الفقرات كما تريد
                                    </p>
                                    <p>
                                        هذا النص يمكن أن يتم تركيبه على أي تصميم
                                        دون مشكلة فلن يبدو وكأنه نص منسوخ، غير
                                        منظم، غير منسق، أو حتى غير مفهوم. لأنه
                                        مازال نصاً بديلاً ومؤقتاً.
                                    </p>
                                </div>
                                <div className="bottom">
                                    <div className="row">
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    الاسم:
                                                </span>
                                                <span>محمد افرندي</span>
                                            </div>
                                        </div>
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    البريد الالكتروني:
                                                </span>
                                                <span>example@gmail.com</span>
                                            </div>
                                        </div>
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    رقم الهاتف:
                                                </span>
                                                <span>+2126001145</span>
                                            </div>
                                        </div>
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    حساب سكايب:
                                                </span>
                                                <span>Your-Name</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fun-fact">
                <div className="fun-fact-content pd-tb">
                    <div className="row">
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="clarity:briefcase-line"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+100</span>
                                    <h4 className="title">مشروع</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="clarity:clock-line"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+30</span>
                                    <h4 className="title">ساعة عمل</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="clarity:home-line"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+10</span>
                                    <h4 className="title">مكاتب</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="carbon:user-multiple"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+300</span>
                                    <h4 className="title">عميل</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="title">
                    <h2>خدماتي</h2>
                </div>
                <div className="service-content pd-tb">
                    <div className="row">
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="cil:brush"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">UI/UX مصمم</h5>
                                    <p className="desc">
                                        هذا النص يمكن أن يتم تركيبه على أي تصميم
                                        دون مشكلة فلن يبدو وكأنه نص منسوخ، غير
                                        منظم
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="fluent:phone-laptop-32-regular"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">تصميم متوافق</h5>
                                    <p className="desc">
                                        هذا النص يمكن أن يتم تركيبه على أي تصميم
                                        دون مشكلة فلن يبدو وكأنه نص منسوخ
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="fluent:code-16-regular"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">تطوير الموقع</h5>
                                    <p className="desc">
                                        هذا النص يمكن أن يتم تركيبه على أي تصميم
                                        دون مشكلة فلن يبدو وكأنه نص منسوخ
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="codicon:settings"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">خيارات متقدمة</h5>
                                    <p className="desc">
                                        هذا النص يمكن أن يتم تركيبه على أي تصميم
                                        دون مشكلة فلن يبدو وكأنه نص منسوخ
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="la:pencil-ruler"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">تصميم فريد</h5>
                                    <p className="desc">
                                        هذا النص يمكن أن يتم تركيبه على أي تصميم
                                        دون مشكلة فلن يبدو وكأنه نص منسوخ
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="bi:phone"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">تطوير التطبيقات</h5>
                                    <p className="desc">
                                        هذا النص يمكن أن يتم تركيبه على أي تصميم
                                        دون مشكلة فلن يبدو وكأنه نص منسوخ
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="experience-skills">
                <div className="title">
                    <h2>خبراتي</h2>
                </div>
                <div className="experience-skills-content row">
                    <div className="experience col-md-6">
                        <div className="experience-content">
                            <div className="experience-bg">
                                <div className="experience-body">
                                    <div className="item">
                                        <span className="top">2021 - 2018</span>
                                        <h5 className="title">مطور ويب</h5>
                                        <p className="desc">
                                            هذا النص يمكن أن يتم تركيبه على أي
                                            تصميم دون مشكلة فلن يبدو وكأنه نص
                                            منسوخ
                                        </p>
                                    </div>
                                    <div className="item study">
                                        <span className="top">2017 - 2013</span>
                                        <h5 className="title">مصمم ويب</h5>
                                        <p className="desc">
                                            هذا النص يمكن أن يتم تركيبه على أي
                                            تصميم دون مشكلة فلن يبدو وكأنه نص
                                            منسوخ
                                        </p>
                                    </div>
                                    <div className="item">
                                        <span className="top">2012 - 2011</span>
                                        <h5 className="title">UI/UX مصمم</h5>
                                        <p className="desc">
                                            هذا النص يمكن أن يتم تركيبه على أي
                                            تصميم دون مشكلة فلن يبدو وكأنه نص
                                            منسوخ
                                        </p>
                                    </div>
                                    <div className="item study">
                                        <span className="top">2010 - 2009</span>
                                        <h5 className="title">Ofppt المغرب</h5>
                                        <p className="desc">
                                            هذا النص يمكن أن يتم تركيبه على أي
                                            تصميم دون مشكلة فلن يبدو وكأنه نص
                                            منسوخ
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="skills col-md-6">
                        <div className="skills-content pd-tb">
                            <div className="item">
                                <span className="title">UX/UI مصمم</span>
                                <span className="number">50%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "50%" }}
                                    ></span>
                                </span>
                            </div>
                            <div className="item">
                                <span className="title">تصميم الويب</span>
                                <span className="number">80%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "80%" }}
                                    ></span>
                                </span>
                            </div>
                            <div className="item">
                                <span className="title">تطوير الويب</span>
                                <span className="number">90%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "90%" }}
                                    ></span>
                                </span>
                            </div>
                            <div className="item">
                                <span className="title">تصميم الجرافيك</span>
                                <span className="number">30%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "30%" }}
                                    ></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
