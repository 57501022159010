import React from "react";
import { Link } from "react-router-dom";

const BlogDetails = () => {
    return (
        <section className="blog-details pd animate-fade link">
            <div className="row">
                <div className="body col-lg-8">
                    <div className="img">
                        <a data-fancybox="" href="../assets/img/blog-2.jpg">
                            <img src="../assets/img/blog-2.jpg" alt="blog" />
                        </a>
                    </div>
                    <div className="info">
                        <div className="title">
                            <h5>
                                I Will Tell You The Truth About Design In The
                                Next 60 Seconds.
                            </h5>
                        </div>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="carbon:user"
                                data-inline="false"
                            />{" "}
                            user
                        </span>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="ic:outline-date-range"
                                data-inline="false"
                            />{" "}
                            25/04/2023
                        </span>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="bi:eye"
                                data-inline="false"
                            />{" "}
                            50
                        </span>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="akar-icons:comment"
                                data-inline="false"
                            />{" "}
                            100
                        </span>
                    </div>
                    <div className="post-content">
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Voluptatum ullam voluptates voluptatem labore
                            ex illum quis debitis deserunt doloribus numquam,
                            fuga doloremque accusamus animi ipsam odit rerum
                            vitae in minima error saepe nulla ipsa. Qui quaerat
                            ipsum ipsa assumenda rerum unde velit nulla mollitia
                            provident iste, culpa tenetur excepturi! Ut.
                        </p>
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Voluptatum ullam voluptates voluptatem labore
                            ex illum quis debitis deserunt doloribus numquam,
                            fuga doloremque accusamus animi ipsam odit rerum.
                        </p>
                        <blockquote>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Id, natus.
                        </blockquote>
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Facilis ipsum delectus, non cum reprehenderit
                            facere magnam dolore doloribus perspiciatis
                            voluptates ex debitis provident aperiam molestiae
                            sint incidunt! Deleniti, unde labore.
                        </p>
                        <a data-fancybox="" href="../assets/img/blog-1.jpg">
                            <img src="../assets/img/blog-1.jpg" alt="blog" />
                        </a>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. A repudiandae odio, culpa provident dolores
                            rerum excepturi blanditiis cum soluta expedita
                            tenetur modi necessitatibus, recusandae vitae
                            consequuntur. Amet nostrum placeat hic.
                        </p>
                    </div>
                    <div className="post-comment">
                        <div className="comment-user">
                            <h6 className="title">comments</h6>
                            <div className="item">
                                <div className="item-img">
                                    <div className="item-img-content">
                                        <img
                                            src="../assets/img/man-1.jpg"
                                            alt="user"
                                        />
                                    </div>
                                </div>
                                <div className="item-area">
                                    <div className="top">
                                        <Link className="name" to="">
                                            mohamed frindi
                                        </Link>
                                        <span className="date">
                                            <i
                                                className="iconify"
                                                data-icon="ic:outline-date-range"
                                                data-inline="false"
                                            />{" "}
                                            25/04/2023
                                        </span>
                                    </div>
                                    <div className="body">
                                        <p className="desc">
                                            Lorem ipsum dolor, sit amet
                                            consectetur adipisicing elit. Ad,
                                            ut. Iste earum odit voluptates, est
                                            eaque vel. At, expedita quam!
                                        </p>
                                    </div>
                                    <div className="footer">
                                        <Link to="." className="link">
                                            <i
                                                className="iconify"
                                                data-icon="mdi-light:message-reply"
                                                data-inline="false"
                                            />{" "}
                                            reply
                                        </Link>
                                        <Link to="." className="link">
                                            <i
                                                className="iconify"
                                                data-icon="simple-line-icons:like"
                                                data-inline="false"
                                            />{" "}
                                            like
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="item reply">
                                <div className="item-img">
                                    <div className="item-img-content">
                                        <img
                                            src="../assets/img/man-1.jpg"
                                            alt="user"
                                        />
                                    </div>
                                </div>
                                <div className="item-area">
                                    <div className="top">
                                        <Link className="name" to="">
                                            mohamed frindi
                                        </Link>
                                        <span className="date">
                                            <i
                                                className="iconify"
                                                data-icon="ic:outline-date-range"
                                                data-inline="false"
                                            />{" "}
                                            25/04/2023
                                        </span>
                                    </div>
                                    <div className="body">
                                        <p className="desc">
                                            Lorem ipsum dolor, sit amet
                                            consectetur adipisicing elit. Ad,
                                            ut. Iste earum odit voluptates, est
                                            eaque vel. At, expedita quam!
                                        </p>
                                    </div>
                                    <div className="footer">
                                        <Link to="" className="link">
                                            <i
                                                className="iconify"
                                                data-icon="mdi-light:message-reply"
                                                data-inline="false"
                                            />{" "}
                                            reply
                                        </Link>
                                        <Link to="" className="link">
                                            <i
                                                className="iconify"
                                                data-icon="simple-line-icons:like"
                                                data-inline="false"
                                            />{" "}
                                            like
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <h6 className="title">Leave Your Comment</h6>
                            <form action="#">
                                <div className="row">
                                    <div className="item col-md-6">
                                        <input
                                            type="text"
                                            placeholder="Type Your Name"
                                        />
                                    </div>
                                    <div className="item col-md-6">
                                        <input
                                            type="email"
                                            placeholder="Type Your Email"
                                        />
                                    </div>
                                    <div className="item col-md-12">
                                        <textarea
                                            cols={30}
                                            rows={10}
                                            placeholder="Type Your Message"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="item col-md-12">
                                        <input
                                            type="submit"
                                            defaultValue="comment"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="aside col-lg-4">
                    <div className="aside-content">
                        <div className="item">
                            <h5 className="title">author</h5>
                            <div className="item-content">
                                <div className="author">
                                    <div className="author-img">
                                        <div className="author-img-content">
                                            <img
                                                src="../assets/img/man-1.jpg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className="author-info">
                                        <span>mohamed frindi</span>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet
                                            consectetur adipisicing elit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <h5 className="title">Search</h5>
                            <div className="item-content">
                                <div className="search">
                                    <div className="input">
                                        <input
                                            type="search"
                                            placeholder="Search"
                                        />
                                        <i
                                            className="iconify"
                                            data-icon="cil:search"
                                            data-inline="false"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <h5 className="title">Categories</h5>
                            <div className="item-content">
                                <div className="categories">
                                    <ul>
                                        <li>
                                            <Link to="">web design</Link>
                                        </li>
                                        <li>
                                            <Link to="">web development</Link>
                                        </li>
                                        <li>
                                            <Link to="">app development</Link>
                                        </li>
                                        <li>
                                            <Link to="">SEO analyst</Link>
                                        </li>
                                        <li>
                                            <Link to="">degitale marketing</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <h5 className="title">recent posts</h5>
                            <div className="item-content">
                                <div className="recent-posts">
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-1.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/04/2023
                                            </span>
                                            <Link to="">
                                                I Will Tell You The Truth About
                                                Design In The Next 60
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-2.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/04/2023
                                            </span>
                                            <Link to="">
                                                I Will Tell You The Truth About
                                                Design In The Next 60
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-3.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/04/2023
                                            </span>
                                            <Link to="">
                                                I Will Tell You The Truth About
                                                Design In The Next 60
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-4.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/03/2023
                                            </span>
                                            <Link to="">
                                                I Will Tell You The Truth About
                                                Design In The Next 60
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogDetails;
