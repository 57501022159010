import { createSlice } from "@reduxjs/toolkit";


const dirReducer = createSlice({
    name : "dir",
    initialState : {
        dir : 'LTR'
    },
    reducers : {
        changeDir : (state , {payload}) => {
            state.dir = payload;
        }
    }
})


export const { changeDir } = dirReducer.actions;
export default dirReducer.reducer;