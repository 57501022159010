import React from "react";

const ButtonMobile = () => {

    // show header for mobile --------------------------
    const ButtonMobileClicked = () => {
        let body = document.querySelector("body");
        let header = document.querySelector(".header-content");
        let btn = document.querySelector(".btn-mobile-open");

        header.classList.add("active");
        body.classList.add("overlay");
        btn.style.display = "none";
    };
    // ------------------x-------------x----------------

    return (
        <button className="btn-mobile-open" onClick={ButtonMobileClicked}>
            <i className="iconify" data-icon="la:bars" data-inline="false"></i>
        </button>
    );
};

export default ButtonMobile;
