import React, { useEffect , useRef, useState } from "react";
// import {Link} from 'react-router-dom';

import Isotope from "isotope-layout";
import { Fancybox } from "@fancyapps/ui";

const Portfolio = () => {
    // isotop js ----------------------------------------
    const isotope = useRef();
    const [filterKey, setFilterKey] = useState("*");

    useEffect(() => {
        isotope.current = new Isotope(".grid", {
            itemSelector: ".item",
            layoutMode: "fitRows",
        });
        // cleanup
        return () => isotope.current.destroy();
    }, []);

    useEffect(() => {
        filterKey === "*"
            ? isotope.current.arrange({ filter: `*` })
            : isotope.current.arrange({ filter: `.${filterKey}` });
    }, [filterKey]);

    const handleFilterKeyChange = (e, key) => {
        setFilterKey(key);

        let buttons = document.querySelectorAll(".filter-button-group button");

        buttons.forEach((btn) => {
            btn.classList.remove("active");
        });

        e.target.classList.add("active");
    };
    // ------------------x-------------x----------------

    // Fancybox js --------------------------------------
    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {
            // Your options go here
        });
    }, []);
    // ------------------x-------------x----------------

    return (
        <section className="portfolio pd animate-top link">
            <div className="title">
                <h2>portfolio</h2>
            </div>
            <div className="portfolio-content pd-tb">
                {/* portfolio nav */}
                <div className="filter-button filter-button-group">
                    <button
                        onClick={(e) => handleFilterKeyChange(e, "*")}
                        className="active"
                    >
                        all
                    </button>
                    <button onClick={(e) => handleFilterKeyChange(e, "voiceover")}>
                        voiceover
                    </button>
                    <button
                        onClick={(e) => handleFilterKeyChange(e, "with-text")}
                    >
                        text overlay
                    </button>
                    <button onClick={(e) => handleFilterKeyChange(e, "aesthetic")}>
                        aesthetic
                    </button>
                    <button onClick={(e) => handleFilterKeyChange(e, "photos")}>
                        photos
                    </button>
                    
                </div>
                {/* portfolio content */}
                <div className="grid row">
                    {/* portfolio item */}
                    <div className="item voiceover col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <video width="320" height="460" controls style={{border: 'none', borderRadius: '20px'}}
                            poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0/v1700002277/ugc-videos/copy_181B6CD2-9907-4757-A835-79C56A457897_mwqldj.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1700002277/ugc-videos/copy_181B6CD2-9907-4757-A835-79C56A457897_mwqldj.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item with-text col-sm-12 col-md-6 col-lg-6 col-xl-4">              
                    <video controls width="320" height="460" style={{border: 'none', borderRadius: '20px'}}
                        poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0.5/v1688697579/ugc-videos/r2xr7feny1jzf2u5pagz.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1688697579/ugc-videos/r2xr7feny1jzf2u5pagz.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item voiceover col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <video width="320" height="460" controls style={{border: 'none', borderRadius: '20px'}}
                            poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_34.6/v1700003003/ugc-videos/E54622F2-E699-471D-8F62-1CD443338BE5_tc2rp6.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1700003003/ugc-videos/E54622F2-E699-471D-8F62-1CD443338BE5_tc2rp6.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item aesthetic col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <video controls width="320" height="460" style={{border: 'none', borderRadius: '20px'}}
                        poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0.5/v1688697552/ugc-videos/ka54fsjreslevm24x0pl.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1688697552/ugc-videos/ka54fsjreslevm24x0pl.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item aesthetic col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <video controls width="320" height="460" style={{border: 'none', borderRadius: '20px'}}
                        poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0.5/v1688697524/ugc-videos/d84najrewsjki1lammov.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1688697524/ugc-videos/d84najrewsjki1lammov.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item with-text col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <video width="320" height="460" controls style={{border: 'none', borderRadius: '20px'}}
                        poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0.5/v1688697597/ugc-videos/adlapl67mx1yekrp0sfs.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1688697597/ugc-videos/adlapl67mx1yekrp0sfs.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item aesthetic col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <video width="320" height="460" controls style={{border: 'none', borderRadius: '20px'}}
                            poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0.5/v1688697539/ugc-videos/xeime3gn8gqjy5wmamjr.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1688697539/ugc-videos/xeime3gn8gqjy5wmamjr.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item with-text col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <video width="320" height="460" controls style={{border: 'none', borderRadius: '20px'}}
                            poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0.5/v1688697581/ugc-videos/yva9ujijpyh6cgwpvcx8.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1688697581/ugc-videos/yva9ujijpyh6cgwpvcx8.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item voiceover col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <video width="320" height="460" controls style={{border: 'none', borderRadius: '20px'}}
                            poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0/v1700002274/ugc-videos/copy_64294DA6-D39C-43E4-8A13-F81BFBDA6341_d0lioi.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1700002274/ugc-videos/copy_64294DA6-D39C-43E4-8A13-F81BFBDA6341_d0lioi.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* portfolio item */}
                    <div className="item voiceover col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <video width="320" height="460" controls style={{border: 'none', borderRadius: '20px'}}
                            poster="https://res.cloudinary.com/ddofsfryq/video/upload/so_0.5/v1700002976/ugc-videos/914BFF63-DC0A-4F0F-8C92-5C4936E97955_x4qbts.jpg">
                        <source src="https://res.cloudinary.com/ddofsfryq/video/upload/v1700002976/ugc-videos/914BFF63-DC0A-4F0F-8C92-5C4936E97955_x4qbts.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* portfolio item */}
                    {/* <div className="item video col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="item-content">
                            <a
                                data-fancybox
                                data-width="740"
                                data-height="460"
                                href="https://www.youtube.com/embed/vZRzs7LlDgA"
                            >
                                <div className="overlay">
                                    <span className="term">video</span>
                                    <h4 className="title">
                                        Project Managment Illustration
                                    </h4>
                                    <span className="more-button">
                                        <span
                                            className="iconify"
                                            data-icon="bx:bx-video"
                                            data-inline="false"
                                        ></span>
                                    </span>
                                </div>
                            </a>
                            <div className="thumb">
                                <img
                                    src="assets/img/portfolio-6.jpg"
                                    alt="portfolio"
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
