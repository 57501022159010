import React, { useEffect } from "react";
import {Link} from "react-router-dom";

import Typed from "typed.js";

const Home = () => {

    // typed js ---------------------------------------
    useEffect(() => {
        new Typed(".typed", {
            strings: ["UGC Creator", "Tiffani", "UGC Creator", "Beauty Content Creator", "Health / Wellness Creator"],
            typeSpeed: 70,
            backSpeed: 20,
            loop: true,
            backDelay: 1000,
            fadeOut: false,
            fadeOutDelay: 500,
            showCursor: true,
            cursorChar: "",
            shuffle: false,
        });
    }, []);
    // ------------------x-------------x----------------

    return (
    <>
    <div>
    <div className="d-md-none m-3 p-3"></div>
    <section className="home animate-top link">
            <div className="row">
                <div className="item col-md-6">
                    <div>
                    <div className="item-content">
                        <span className="top">hi there!</span>
                        <h3 className="im">
                            I'M <span className="typed"></span>
                        </h3>
                        <p className="desc">
                            UGC Portfolio | info@tiffanialvarez.com
                        </p>
                        <p className="desc">
                        I am passionate about creating engaging and insightful content.
                        <br/>My goal is to bring you high-quality content that aligns with your brand's vision, 
                        as capture the essence of your products in a creative and engaging way. I'm here to make your brand 
                        genuinely communicate with its target audience with the end goal of creating conversions.
                        </p>
                        <p className="desc">
                        Lets work together!
                        </p>
                        <div className="socials">
                            <ul>
                                <li>
                                    <a href="mailto:info@tiffanialvarez.com">
                                        <i
                                            className="iconify"
                                            data-icon="clarity:email-line"
                                            data-inline="false"
                                        ></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <Link to="/">
                                        <i
                                            className="iconify"
                                            data-icon="akar-icons:twitter-fill"
                                            data-inline="false"
                                        ></i>
                                    </Link>
                                </li> */}
                                <li>
                                    <a href="https://www.instagram.com/tiffanialvarez/" target="_blank" rel="noopener noreferrer">
                                        <i
                                            className="iconify"
                                            data-icon="uil:instagram"
                                            data-inline="false"
                                        ></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tiktok.com/@tiffanialvarez?_t=8dbQNTHIICs&_r=1" target="_blank" rel="noopener noreferrer">
                                        <i
                                            className="iconify"
                                            data-icon="ic:baseline-tiktok"
                                            data-inline="false"
                                        ></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                    <div className="d-md-none d-flex justify-content-center">
                            <Link to="/about"><button className="btn m-1"><i className="iconify" data-icon="ant-design:user-outlined" data-inline="false"></i>About</button></Link>
                            </div>
                            <div className="d-md-none d-flex justify-content-center">
                            <Link to="/portfolio"><button className="btn m-1"><i className="iconify" data-icon="clarity:briefcase-line" data-inline="false"></i>Portfolio</button></Link>
                            </div>
                            <div className="d-md-none d-flex justify-content-center">
                            <Link to="/contact"><button className="btn m-1"><i className="iconify" data-icon="clarity:envelope-line" data-inline="false"></i>Contact</button></Link>
                            </div>
                            </div>
                    </div>
                </div>
                <div className="img col-md-6 d-none d-md-block">
                    <img src="assets/img/tiffani-homepage.png" alt="me" style={{maxWidth:"350px"}}/>
                </div>
            </div>
        </section>
        </div>
    </>
        
    );
};

export default Home;
