import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Morphext from "../assets/js/Morphext";

const Header = () => {
    // text clavie efect ------------------------------
    useEffect(() => {
        let txt = new Morphext({
            el: ".js-rotating",
            values: [
                "UGC Creator",
                "Beauty Content",
                "Health & Wellness Content",
                "Fashion Content",
                "Home Decor Content",
            ],
            speed: 3500,
        });

        txt.run();
    }, []);
    // ------------------x-------------x----------------

    // hide header ------------------------------------
    const btnHeaderClose = () => {
        let body = document.querySelector("body");
        let header = document.querySelector(".header-content");
        let btn = document.querySelector(".btn-mobile-open");

        header.classList.remove("active");
        body.classList.remove("overlay");
        btn.style.display = "block";
    };
    // ------------------x-------------x----------------

    return (
        <header className="header">
            <div className="header-content">
                {/* mobile btn close */}
                <button className="btn-mobile-close" onClick={btnHeaderClose}>
                    <i
                        className="iconify"
                        data-icon="clarity:window-close-line"
                        data-inline="false"
                    ></i>
                </button>

                <div className="user">
                    <div className="img">
                        <img src="assets/img/tiffani-profile.jpg" alt="user" />
                    </div>
                    <h1 className="name">Tiffani</h1>
                    <div className="work">
                        <span className="js-rotating animated"></span>
                    </div>
                </div>
                <div className="nav">
                    <ul className="nav-list">
                        <li onClick={btnHeaderClose}>
                            <span
                                className="iconify"
                                data-icon="clarity:home-line"
                                data-inline="false"
                            ></span>
                            <Link to="/"> home</Link>
                        </li>
                        <li onClick={btnHeaderClose}>
                            <span
                                className="iconify"
                                data-icon="ant-design:user-outlined"
                                data-inline="false"
                            ></span>
                            <Link to="/about"> about</Link>
                        </li>
                        <li onClick={btnHeaderClose}>
                            <span
                                className="iconify"
                                data-icon="clarity:briefcase-line"
                                data-inline="false"
                            ></span>
                            <Link to="/portfolio">portfolio</Link>
                        </li>
                        {/* <li>
                            <span
                                className="iconify"
                                data-icon="ion:create-outline"
                                data-inline="false"
                            ></span>
                            <Link to="/blog">blog</Link>
                        </li> */}
                        <li onClick={btnHeaderClose}>
                            <span
                                className="iconify"
                                data-icon="clarity:envelope-line"
                                data-inline="false"
                            ></span>
                            <Link to="/contact">contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="cpr">
                    <span>© {new Date().getFullYear()} All rights reserved.</span>
                </div>
                <div className="download">
                    <i
                        className="iconify icon"
                        data-icon="carbon:cloud-download"
                        data-inline="false"
                    ></i>
                    <span className="hv">download <br/> media kit</span>
                </div>
            </div>
        </header>
    );
};

export default Header;
