import React from "react";
import {Link} from "react-router-dom";

const PortfolioDetails = () => {
    return (
        <section className="portfolio-details pd animate-bottom link">
            <div className="row">
                <div className="area left col-md-4">
                    <div className="area-content">
                        <div className="item">
                            <div className="item-content">
                                <h6 className="title">Project</h6>
                                <div className="info">
                                    <ul className="list-block">
                                        <li>
                                            <strong className="min">
                                                client
                                            </strong>
                                            <Link to="/"> upwork</Link>
                                        </li>
                                        <li>
                                            <strong className="min">
                                                Location
                                            </strong>{" "}
                                            USA
                                        </li>
                                        <li>
                                            <strong className="min">
                                                Year
                                            </strong>{" "}
                                            2021
                                        </li>
                                        <li>
                                            <strong className="min">
                                                Sector
                                            </strong>{" "}
                                            South
                                        </li>
                                        <li>
                                            <strong className="min">
                                                Budget
                                            </strong>{" "}
                                            $1000
                                        </li>
                                        <li>
                                            <strong className="min">
                                                Demo
                                            </strong>
                                            <Link to=""> www.example.com</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-content">
                                <h6 className="title">Tools</h6>
                                <div className="info">
                                    <ul className="list-line">
                                        <li>HTML</li>
                                        <li>CSS3</li>
                                        <li>JavaScript</li>
                                        <li>Vue JS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="area right col-md-8">
                    <div className="item-content">
                        <div>
                            <h5 className="title">
                                Person Portfolio HTML Template
                            </h5>
                            <p className="desc">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit Magnam aspernatur suscipit
                                porro ullam Lorem ipsum dolor sit, amet
                                consectetur adipisicing elit. Deserunt autem,
                                animi libero unde iusto quisquam tenetur
                                necessitatibus suscipit ea tempora.
                            </p>
                        </div>
                        <div>
                            <h5 className="title">Fuature</h5>
                            <ul className="list">
                                <li>Built with latest React v17+</li>
                                <li>Light &amp; Dark Version</li>
                                <li>Ultra Super Fast</li>
                                <li>Fully Responsive</li>
                                <li>CSS3 Animations</li>
                                <li>SEO optimized</li>
                                <li>Mobile Navigations</li>
                                <li>Fully Responsive to all devices</li>
                                <li>Developer Friendly Coding</li>
                                <li>Sticky Navigation</li>
                                <li>Very Well Documented</li>
                                <li>Free Updates</li>
                            </ul>
                        </div>
                        <div>
                            <h5 className="title">Project Screenshot</h5>
                            <a data-fancybox="" href="../assets/img/work-1.png">
                                <img src="../assets/img/work-1.png" alt="img" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioDetails;
