import React, { useRef } from "react";
import {sendForm} from "@emailjs/browser";
import toastr from "toastr";

const Contact = () => {

    const form = useRef();

    const handelEmailSubmit = async (e) => {

        e.preventDefault();
        
        try {

            let sendEmail = await sendForm(
                    process.env.REACT_APP_EMAIL_SERVICE_ID, // server id
                    process.env.REACT_APP_EMAIL_TEMPLATE_ID, // template id
                    form.current,
                    process.env.REACT_APP_EMAIL_PUBLIC_KEY // public key
            )

            console.log(sendEmail.text);
            toastr.success(`The message has been sent successfully !!`);

        } catch (error) {
            toastr.error(error?.text);
        }

    }


    return (
        <section className="contact pd animate-top link">
            <div className="title">
                <h2>contact</h2>
            </div>
            {/* contact content */}
            <div className="contact-content pd-tb">
                <div className="row">
                    {/* <!-- contact item left --> */}
                    <div className="item left col-lg-6">
                        {/* <h5 className="title">contact info</h5>
                        <p className="desc">Always available for freelance work if the right project comes along, Feel free to contact me!</p> */}
                        {/* <!-- info --> */}
                        <div className="info">
                            {/* <!-- info item --> */}
                            <div className="info-item">
                                <div className="top">
                                    <i
                                        className="iconify"
                                        data-icon="carbon:location"
                                        data-inline="false"
                                    ></i>
                                    <h6 className="info-title">
                                        Location
                                    </h6>
                                </div>
                                <div className="area">
                                    <span>- Miami, Florida</span>
                                    <span>- Medellin, Colombia</span>
                                </div>
                            </div>
                            {/* <!-- info item --> */}
                            <div className="info-item">
                                <div className="top">
                                    <i
                                        className="iconify"
                                        data-icon="fluent:mail-48-regular"
                                        data-inline="false"
                                    ></i>
                                    <h6 className="info-title">email</h6>
                                </div>
                                <div className="area">
                                    <span>info@tiffanialvarez.com</span>
                                </div>
                            </div>
                            {/* <!-- info item --> */}
                            <div className="info-item">
                                <div className="top">
                                    <i
                                        className="iconify"
                                        data-icon="carbon:share"
                                        data-inline="false"
                                    ></i>
                                    <h6 className="info-title">Social Media</h6>
                                </div>
                                <div className="area">
                                    <a href="https://www.instagram.com/tiffanialvarez/" target="_blank" rel="noopener noreferrer">
                                        <span className="title">
                                            Instagram: @tiffanialvarez
                                        </span>
                                    </a>            
                                    <a href="https://www.tiktok.com/@tiffanialvarez?_t=8dbQNTHIICs&_r=1" target="_blank" rel="noopener noreferrer">
                                        <span className="title">
                                            TikTok: @tiffanialvarez
                                        </span>
                                    </a> 
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact item right --> */}
                    <div className="item right col-lg-6">
                        <div className="item-content">
                            <form ref={form} onSubmit={handelEmailSubmit}>
                                <div className="row">
                                    <div className="input remove-pd col-md-6">
                                        <input
                                            type="text"
                                            name="user_name"
                                            placeholder="Your Name"
                                            required
                                        />
                                    </div>
                                    <div className="input col-md-6">
                                        <input
                                            type="email"
                                            name="user_email"
                                            placeholder="Email"
                                            required
                                        />
                                    </div>
                                    <div className="input col-md-12">
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                        />
                                    </div>
                                    <div className="input col-md-12">
                                        <textarea
                                            cols="30"
                                            rows="10"
                                            name="message"
                                            placeholder="Your Message"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="input col-md-12">
                                        <input
                                            type="submit"
                                            value="Contact Me"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
