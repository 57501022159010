import React, { useEffect, useState } from "react";

const Spinner = () => {

    const [loading , setLoading] = useState(false);

    // hide preloder ----------------------------------
    useEffect(() => { 

        // window.addEventListener('load', () => {
        //     setLoading(true)
        // });

        setTimeout(() => {
            setLoading(true);
        } , 3000);

    })

    // ------------------x-------------x----------------

    return (
        <div className="preloader" style={{display : loading && `none`}}>
            <span className="preloader-spinner"></span>
        </div>
    );
};

export default Spinner;
