import React from "react";
import {Link} from "react-router-dom";

const Blog = () => {
    return <section className="blog pd animate-left link">
            <div className="title">
                <h2>المدونة</h2>
            </div>
            {/* blog content */}
            <div className="blog-content pd-tb">
                {/* blog items */}
                <div className="row">
                    {/* blog item */}
                    <div className="item col-md-6">
                        <div className="item-content">
                            <div className="img">
                                <Link to="/blog/123"><img src="assets/img/blog-1.jpg" alt="blog" /></Link>
                            </div>
                            <div className="info">
                                <div className="top">
                                    <span className="date"><i className="iconify" data-icon="ic:outline-date-range" data-inline="false"></i> 25/04/2023</span>
                                    <span className="views"><i className="iconify" data-icon="bi:eye" data-inline="false"></i> 100</span>
                                    <span className="comment"><i className="iconify" data-icon="akar-icons:comment" data-inline="false"></i> 10</span>
                                </div>
                                <Link to="/blog/123" className="title">هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة</Link>
                            </div>
                        </div>
                    </div>
                    {/* blog item */}
                    <div className="item col-md-6">
                        <div className="item-content">
                            <div className="img">
                                <Link to="/blog/123"><img src="assets/img/blog-2.jpg" alt="blog" /></Link>
                            </div>
                            <div className="info">
                                <div className="top">
                                    <span className="date"><i className="iconify" data-icon="ic:outline-date-range" data-inline="false"></i> 25/04/2023</span>
                                    <span className="views"><i className="iconify" data-icon="bi:eye" data-inline="false"></i> 100</span>
                                    <span className="comment"><i className="iconify" data-icon="akar-icons:comment" data-inline="false"></i> 10</span>
                                </div>
                                <Link to="/blog/123" className="title">هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة</Link>
                            </div>
                        </div>
                    </div>
                    {/* blog item */}
                    <div className="item col-md-6">
                        <div className="item-content">
                            <div className="img">
                                <Link to="/blog/123"><img src="assets/img/blog-3.jpg" alt="blog" /></Link>
                            </div>
                            <div className="info">
                                <div className="top">
                                    <span className="date"><i className="iconify" data-icon="ic:outline-date-range" data-inline="false"></i> 25/04/2023</span>
                                    <span className="views"><i className="iconify" data-icon="bi:eye" data-inline="false"></i> 100</span>
                                    <span className="comment"><i className="iconify" data-icon="akar-icons:comment" data-inline="false"></i> 10</span>
                                </div>
                                <Link to="/blog/123" className="title">هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة</Link>
                            </div>
                        </div>
                    </div>
                    {/* blog item */}
                    <div className="item col-md-6">
                        <div className="item-content">
                            <div className="img">
                                <Link to="/blog/123"><img src="assets/img/blog-4.jpg" alt="blog" /></Link>
                            </div>
                            <div className="info">
                                <div className="top">
                                    <span className="date"><i className="iconify" data-icon="ic:outline-date-range" data-inline="false"></i> 25/04/2023</span>
                                    <span className="views"><i className="iconify" data-icon="bi:eye" data-inline="false"></i> 100</span>
                                    <span className="comment"><i className="iconify" data-icon="akar-icons:comment" data-inline="false"></i> 10</span>
                                </div>
                                <Link to="/blog/123" className="title">هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* btn read more */}
                <button className="btn">المزيد <i className="iconify" data-icon="ic:baseline-expand-more" data-inline="false"></i></button>
            </div>
        </section>;
};

export default Blog;
