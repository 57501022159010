import React from "react";
import { Link } from "react-router-dom";

const BlogDetails = () => {
    return (
        <section className="blog-details pd animate-fade link">
            <div className="row">
                <div className="body col-lg-8">
                    <div className="img">
                        <a data-fancybox="" href="../assets/img/blog-2.jpg">
                            <img src="../assets/img/blog-2.jpg" alt="blog" />
                        </a>
                    </div>
                    <div className="info">
                        <div className="title">
                            <h5>
                                هذا النص يمكن أن يتم تركيبه على أي تصميم دون
                                مشكلة
                            </h5>
                        </div>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="carbon:user"
                                data-inline="false"
                            />{" "}
                            محمد
                        </span>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="ic:outline-date-range"
                                data-inline="false"
                            />{" "}
                            25/04/2023
                        </span>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="bi:eye"
                                data-inline="false"
                            />{" "}
                            50
                        </span>
                        <span className="info-item">
                            <i
                                className="iconify"
                                data-icon="akar-icons:comment"
                                data-inline="false"
                            />{" "}
                            100
                        </span>
                    </div>
                    <div className="post-content">
                        <p>
                            هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة،
                            لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك
                            أن تولد مثل هذا النص أو العديد من النصوص الأخرى
                            إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا
                            كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص
                            العربى زيادة عدد الفقرات كما تريد، النص لن يبدو
                            مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد
                            لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى
                            كثير من الأحيان أن يطلع على صورة حقيقية لتصميم
                            الموقع.
                        </p>
                        <p>
                            ومن هنا وجب على المصمم أن يضع نصوصا مؤقتة على
                            التصميم ليظهر للعميل الشكل كاملاً،دور مولد النص
                            العربى أن يوفر على المصمم عناء البحث عن نص بديل لا
                            علاقة له بالموضوع الذى يتحدث عنه التصميم فيظهر بشكل
                            لا يليق.
                        </p>
                        <blockquote>
                            هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة
                            فلن يبدو وكأنه نص منسوخ
                        </blockquote>
                        <p>
                            هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة
                            فلن يبدو وكأنه نص منسوخ، غير منظم، غير منسق، أو حتى
                            غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.
                        </p>
                        <a data-fancybox="" href="../assets/img/blog-1.jpg">
                            <img src="../assets/img/blog-1.jpg" alt="blog" />
                        </a>
                        <p>
                            هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة
                            فلن يبدو وكأنه نص منسوخ، غير منظم، غير منسق، أو حتى
                            غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً
                        </p>
                    </div>
                    <div className="post-comment">
                        <div className="comment-user">
                            <h6 className="title">التعليقات</h6>
                            <div className="item">
                                <div className="item-img">
                                    <div className="item-img-content">
                                        <img
                                            src="../assets/img/man-1.jpg"
                                            alt="user"
                                        />
                                    </div>
                                </div>
                                <div className="item-area">
                                    <div className="top">
                                        <Link className="name" to="">
                                            محمد افرندي
                                        </Link>
                                        <span className="date">
                                            <i
                                                className="iconify"
                                                data-icon="ic:outline-date-range"
                                                data-inline="false"
                                            />{" "}
                                            25/04/2023
                                        </span>
                                    </div>
                                    <div className="body">
                                        <p className="desc">
                                            هذا النص يمكن أن يتم تركيبه على أي
                                            تصميم دون مشكلة فلن يبدو وكأنه نص
                                            منسوخ
                                        </p>
                                    </div>
                                    <div className="footer">
                                        <Link to="." className="link">
                                            <i
                                                className="iconify"
                                                data-icon="mdi-light:message-reply"
                                                data-inline="false"
                                            />{" "}
                                            رد
                                        </Link>
                                        <Link to="." className="link">
                                            <i
                                                className="iconify"
                                                data-icon="simple-line-icons:like"
                                                data-inline="false"
                                            />{" "}
                                            اعجاب
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="item reply">
                                <div className="item-img">
                                    <div className="item-img-content">
                                        <img
                                            src="../assets/img/man-1.jpg"
                                            alt="user"
                                        />
                                    </div>
                                </div>
                                <div className="item-area">
                                    <div className="top">
                                        <Link className="name" to="">
                                            محمد افرندي
                                        </Link>
                                        <span className="date">
                                            <i
                                                className="iconify"
                                                data-icon="ic:outline-date-range"
                                                data-inline="false"
                                            />{" "}
                                            25/04/2023
                                        </span>
                                    </div>
                                    <div className="body">
                                        <p className="desc">
                                            هذا النص يمكن أن يتم تركيبه على أي
                                            تصميم دون مشكلة فلن يبدو وكأنه نص
                                            منسوخ
                                        </p>
                                    </div>
                                    <div className="footer">
                                        <Link to="" className="link">
                                            <i
                                                className="iconify"
                                                data-icon="mdi-light:message-reply"
                                                data-inline="false"
                                            />{" "}
                                            رد
                                        </Link>
                                        <Link to="" className="link">
                                            <i
                                                className="iconify"
                                                data-icon="simple-line-icons:like"
                                                data-inline="false"
                                            />{" "}
                                            اعجاب
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <h6 className="title">اضافة تعليق</h6>
                            <form action="#">
                                <div className="row">
                                    <div className="item col-md-6">
                                        <input
                                            type="text"
                                            placeholder="الاسم"
                                        />
                                    </div>
                                    <div className="item col-md-6">
                                        <input
                                            type="email"
                                            placeholder="البريد"
                                        />
                                    </div>
                                    <div className="item col-md-12">
                                        <textarea
                                            cols={30}
                                            rows={10}
                                            placeholder="الرسالة"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="item col-md-12">
                                        <input
                                            type="submit"
                                            defaultValue="تعليق"
                                            value="تعليق"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="aside col-lg-4">
                    <div className="aside-content">
                        <div className="item">
                            <h5 className="title">الكاتب</h5>
                            <div className="item-content">
                                <div className="author">
                                    <div className="author-img">
                                        <div className="author-img-content">
                                            <img
                                                src="../assets/img/man-1.jpg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className="author-info">
                                        <span>محمد افرندي</span>
                                        <p className="desc">
                                            هذا النص يمكن أن يتم تركيبه على أي
                                            تصميم
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <h5 className="title">البحث</h5>
                            <div className="item-content">
                                <div className="search">
                                    <div className="input">
                                        <input
                                            type="search"
                                            placeholder="بحث"
                                        />
                                        <i
                                            className="iconify"
                                            data-icon="cil:search"
                                            data-inline="false"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <h5 className="title">التصنيفات</h5>
                            <div className="item-content">
                                <div className="categories">
                                    <ul>
                                        <li>
                                            <Link to="">تصميم ويب</Link>
                                        </li>
                                        <li>
                                            <Link to="">تطوير ويب</Link>
                                        </li>
                                        <li>
                                            <Link to="">تطوير التطبيقات</Link>
                                        </li>
                                        <li>
                                            <Link to="">السيو</Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                التسويق الالكتروني
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <h5 className="title">مواضيع شيقة</h5>
                            <div className="item-content">
                                <div className="recent-posts">
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-1.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/04/2023
                                            </span>
                                            <Link to="">
                                                هذا النص يمكن أن يتم تركيبه على
                                                أي تصميم دون مشكلة
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-2.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/04/2023
                                            </span>
                                            <Link to="">
                                                هذا النص يمكن أن يتم تركيبه على
                                                أي تصميم دون مشكلة
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-3.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/04/2023
                                            </span>
                                            <Link to="">
                                                هذا النص يمكن أن يتم تركيبه على
                                                أي تصميم دون مشكلة
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="recent-item">
                                        <div className="img">
                                            <div className="img-content">
                                                <img
                                                    src="../assets/img/blog-4.jpg"
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="date">
                                                <i
                                                    className="iconify"
                                                    data-icon="ic:outline-date-range"
                                                    data-inline="false"
                                                />{" "}
                                                25/04/2023
                                            </span>
                                            <Link to="">
                                                هذا النص يمكن أن يتم تركيبه على
                                                أي تصميم دون مشكلة
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogDetails;
