import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";


// components
import Header from "./components/RTL/Header";
import ButtonMobile from "./components/ButtonMobile";
import Settings from "./components/RTL/Settings";

// pages
import Home from "./pages/RTL/Home";
import About from "./pages/RTL/About";
import Portfolio from "./pages/RTL/Portfolio";
import PortfolioDetails from "./pages/RTL/PortfolioDetails";
import Blog from "./pages/RTL/Blog";
import BlogDetails from "./pages/RTL/BlogDetails";
import Contact from "./pages/RTL/Contact";



function RTL () {
    // use theme
    useEffect(() => {
        let body = document.querySelector("body");

        let theme_mode = localStorage.getItem("theme-mode");
        let theme_color = localStorage.getItem("theme-color");

        if (theme_mode || theme_color) {
            body.className = `${theme_mode} ${theme_color}`;
        }
    }, []);

    // Shut off when you press anywhere
    document.addEventListener("click", (e) => {
        e.stopPropagation();

        let body = document.querySelector("body");
        let header = document.querySelector(".header-content");
        let btn = document.querySelector(".btn-mobile-open");

        if (e.target === body) {
            header.classList.remove("active");
            body.classList.remove("overlay");
            btn.style.display = "block";
        }
    });

    return (
        <div className="content">
            <Header />
            <ButtonMobile />
            <Settings />

            <Routes>
                <Route index path="/" element={<Home />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/portfolio" element={<Portfolio />}></Route>
                <Route
                    path="/portfolio/:id"
                    element={<PortfolioDetails />}
                ></Route>
                <Route path="/blog" element={<Blog />}></Route>
                <Route path="/blog/:id" element={<BlogDetails />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
            </Routes>
        </div>
    );
}

export default RTL;
