class Morphext {
    constructor(options) {
        this.options = options;

        this.el = document.querySelector(options.el);
        this.values = options.values;
        this.speed = options.speed;
    }

    run() {
        // append values for el
        let el = this.el;
        let values = this.values;
        let speed = this.speed;

        let actived = "";
        let counter = 0;

        // default active
        el.innerHTML = values[0];

        setInterval(() => {
            counter++;
            actived = values[counter - 1];

            if (counter >= values.length) {
                counter = 0;
            }

            el.classList.add("animate-fade");
            el.innerHTML = actived;

            setTimeout(() => {
                el.classList.remove("animate-fade");
            }, speed / 2);
        }, speed);
    }
}

export default Morphext;