import React from "react";
import {Link} from "react-router-dom";

const PortfolioDetails = () => {
    return (
        <section className="portfolio-details pd animate-bottom link">
            <div className="row">
                <div className="area left col-md-4">
                    <div className="area-content">
                        <div className="item">
                            <div className="item-content">
                                <h6 className="title">المشروع</h6>
                                <div className="info">
                                    <ul className="list-block">
                                        <li>
                                            <strong className="min">
                                                الزبون
                                            </strong>
                                            <Link to="/"> Envanto</Link>
                                        </li>
                                        <li>
                                            <strong className="min">
                                                الموقع
                                            </strong>{" "}
                                            USA
                                        </li>
                                        <li>
                                            <strong className="min">
                                                السنة
                                            </strong>{" "}
                                            2021
                                        </li>
                                        <li>
                                            <strong className="min">
                                                المدينة
                                            </strong>{" "}
                                            South
                                        </li>
                                        <li>
                                            <strong className="min">
                                                القيمة
                                            </strong>{" "}
                                            $1000
                                        </li>
                                        <li>
                                            <strong className="min">
                                                الموقع
                                            </strong>
                                            <Link to=""> www.example.com</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-content">
                                <h6 className="title">الادوات المستخدمة</h6>
                                <div className="info">
                                    <ul className="list-line">
                                        <li>HTML</li>
                                        <li>CSS3</li>
                                        <li>JavaScript</li>
                                        <li>Vue JS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="area right col-md-8">
                    <div className="item-content">
                        <div>
                            <h5 className="title">
                                موقع شخصي متوافق مع جميع الشاشات
                            </h5>
                            <p className="desc">
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس
                                المساحة، لقد تم توليد هذا النص من مولد النص
                                العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد
                                من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى
                                يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من
                                الفقرات يتيح لك مولد النص العربى زيادة عدد
                                الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي
                                أخطاء لغوية، مولد النص العربى مفيد لمصممي
                                المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير
                                من الأحيان أن يطلع على صورة حقيقية لتصميم
                                الموقع.
                            </p>
                        </div>
                        <div>
                            <h5 className="title">مميزات الموقع</h5>
                            <ul className="list">
                                <li>Built with latest React v17+</li>
                                <li>Light &amp; Dark Version</li>
                                <li>Ultra Super Fast</li>
                                <li>Fully Responsive</li>
                                <li>CSS3 Animations</li>
                                <li>SEO optimized</li>
                                <li>Mobile Navigations</li>
                                <li>Fully Responsive to all devices</li>
                                <li>Developer Friendly Coding</li>
                                <li>Sticky Navigation</li>
                                <li>Very Well Documented</li>
                                <li>Free Updates</li>
                            </ul>
                        </div>
                        <div>
                            <h5 className="title">صور الموقع</h5>
                            <a data-fancybox="" href="../assets/img/work-1.png">
                                <img src="../assets/img/work-1.png" alt="img" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioDetails;
