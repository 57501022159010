import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Isotope from "isotope-layout";
import { Fancybox } from "@fancyapps/ui";

const Portfolio = () => {
    // isotop js ------------------------------------------
    const isotope = useRef();
    const [filterKey, setFilterKey] = useState("*");

    useEffect(() => {
        isotope.current = new Isotope(".grid", {
            itemSelector: ".item",
            layoutMode: "fitRows",
            originLeft: false,
        });
        // cleanup
        return () => isotope.current.destroy();
    }, []);

    useEffect(() => {
        filterKey === "*"
            ? isotope.current.arrange({ filter: `*` })
            : isotope.current.arrange({ filter: `.${filterKey}` });
    }, [filterKey]);

    const handleFilterKeyChange = (e, key) => {
        setFilterKey(key);

        let buttons = document.querySelectorAll(".filter-button-group button");

        buttons.forEach((btn) => {
            btn.classList.remove("active");
        });

        e.target.classList.add("active");
    };
    // ------------------x-------------x----------------

    // Fancybox js ============================================
    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {
            // Your options go here
        });
    }, []);
    // ------------------x-------------x----------------


    return (
        <section className="portfolio pd animate-top link">
            <div className="title">
                <h2>اعمالنا</h2>
            </div>
            <div className="portfolio-content pd-tb">
                {/* portfolio nav */}
                <div className="filter-button filter-button-group">
                    <button
                        onClick={(e) => handleFilterKeyChange(e, "*")}
                        className="active"
                    >
                        الكل
                    </button>
                    <button
                        onClick={(e) => handleFilterKeyChange(e, "creative")}
                    >
                        ابداعي
                    </button>
                    <button onClick={(e) => handleFilterKeyChange(e, "design")}>
                        صور
                    </button>
                    <button onClick={(e) => handleFilterKeyChange(e, "art")}>
                        غناء
                    </button>
                    <button onClick={(e) => handleFilterKeyChange(e, "video")}>
                        مقطع
                    </button>
                </div>
                {/* portfolio content */}
                <div className="grid row">
                    {/* portfolio item */}
                    <div className="item art col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="item-content">
                            <a
                                data-fancybox
                                data-type="iframe"
                                data-height="360"
                                data-width="560"
                                href="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/471358728&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                            >
                                <div className="overlay">
                                    <span className="term">غناء</span>
                                    <h4 className="title">هذا النص تجريبي</h4>
                                    <span className="more-button">
                                        <span
                                            className="iconify"
                                            data-icon="fluent:headphones-sound-wave-20-filled"
                                            data-inline="false"
                                        ></span>
                                    </span>
                                </div>
                            </a>
                            <div className="thumb">
                                <img
                                    src="assets/img/portfolio-1.jpg"
                                    alt="portfolio"
                                />
                            </div>
                        </div>
                    </div>
                    {/* portfolio item */}
                    <div className="item design col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="item-content">
                            <a
                                data-fancybox="gallery"
                                href="../assets/img/portfolio-5.jpg"
                            >
                                <div className="overlay">
                                    <span className="term">صور</span>
                                    <h4 className="title">هذا النص تجريبي</h4>
                                    <span className="more-button">
                                        <span
                                            className="iconify"
                                            data-icon="bx:bx-image-alt"
                                            data-inline="false"
                                        ></span>
                                    </span>
                                </div>
                            </a>
                            <div className="thumb">
                                <img
                                    src="assets/img/portfolio-5.jpg"
                                    alt="portfolio"
                                />
                            </div>
                        </div>
                    </div>
                    {/* portfolio item */}
                    <div className="item creative col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="item-content">
                            <Link to="/portfolio/123">
                                <div className="overlay">
                                    <span className="term">ابداعي</span>
                                    <h4 className="title">هذا النص تجريبي</h4>
                                    <span className="more-button">
                                        <span
                                            className="iconify"
                                            data-icon="clarity:add-line"
                                            data-inline="false"
                                        ></span>
                                    </span>
                                </div>
                            </Link>
                            <div className="thumb">
                                <img
                                    src="assets/img/portfolio-3.jpg"
                                    alt="portfolio"
                                />
                            </div>
                        </div>
                    </div>
                    {/* portfolio item */}
                    <div className="item design col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="item-content">
                            <a
                                data-fancybox="gallery"
                                href="../assets/img/portfolio-4.jpg"
                            >
                                <div className="overlay">
                                    <span className="term">صور</span>
                                    <h4 className="title">هذا النص تجريبي</h4>
                                    <span className="more-button">
                                        <span
                                            className="iconify"
                                            data-icon="bx:bx-image-alt"
                                            data-inline="false"
                                        ></span>
                                    </span>
                                </div>
                            </a>
                            <div className="thumb">
                                <img
                                    src="assets/img/portfolio-4.jpg"
                                    alt="portfolio"
                                />
                            </div>
                        </div>
                    </div>
                    {/* portfolio item */}
                    <div className="item art col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="item-content">
                            <a
                                data-fancybox
                                data-type="iframe"
                                data-height="360"
                                data-width="560"
                                href="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/738502420&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                            >
                                <div className="overlay">
                                    <span className="term">غناء</span>
                                    <h4 className="title">هذا النص تجريبي</h4>
                                    <span className="more-button">
                                        <span
                                            className="iconify"
                                            data-icon="fluent:headphones-sound-wave-20-filled"
                                            data-inline="false"
                                        ></span>
                                    </span>
                                </div>
                            </a>
                            <div className="thumb">
                                <img
                                    src="assets/img/portfolio-2.jpg"
                                    alt="portfolio"
                                />
                            </div>
                        </div>
                    </div>
                    {/* portfolio item */}
                    <div className="item video col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="item-content">
                            <a
                                data-fancybox
                                data-width="740"
                                data-height="460"
                                href="https://youtu.be/rmgMmWee-Xc"
                            >
                                <div className="overlay">
                                    <span className="term">مقطع</span>
                                    <h4 className="title">هذا النص تجريبي</h4>
                                    <span className="more-button">
                                        <span
                                            className="iconify"
                                            data-icon="bx:bx-video"
                                            data-inline="false"
                                        ></span>
                                    </span>
                                </div>
                            </a>
                            <div className="thumb">
                                <img
                                    src="assets/img/portfolio-6.jpg"
                                    alt="portfolio"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
