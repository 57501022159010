import { configureStore } from "@reduxjs/toolkit";

// Reducers
import dirReducer from "./featurs/dirReducer";

const store = configureStore({
    reducer: {
        dir : dirReducer,
    },
});


export default store;