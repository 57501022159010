import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import LTR from "./LTR";
import RTL from "./RTL";

import Spinner from "./components/Spinner";


const App = () => {

    // dir state
    const { dir } = useSelector((store) => store.dir);

    // change dir 
    useEffect(() => {

        let html = document.querySelector("html");

        if (dir === "LTR") {
            html.dir = "ltr";
            html.lang = "en";
            html.className = "LTR-style";
        } else if (dir === "RTL") {
            html.dir = "rtl";
            html.lang = "ar";
            html.className = "RTL-style";
        }

    }, [dir]);


    return (
        <>
            <Spinner />
            {dir === "LTR" && <LTR />}
            {dir === "RTL" && <RTL />}
        </>
    );

};

export default App;
