import React from "react";
// import {useLocation} from "react-router-dom";
// import {Link} from "react-router-dom";
// import {useDispatch} from "react-redux";

// import { changeDir } from "../redux/featurs/dirReducer";


const Settings = () => {
    // const dispatch = useDispatch();
    // const location = useLocation();

    // btn setting toggle show and hide settings ---------
    const btnSettingClicked = () => {
        let settings = document.querySelector(".settings");
        settings.classList.toggle("active");
    };
    // ------------------x-------------x----------------

    // Shut off when you press anywhere -------
    document.addEventListener("click", (e) => {
        e.stopPropagation();

        let settings = document.querySelector(".settings");
        let settingsChildrens = Array.from(
            document.querySelector(".settings").getElementsByTagName("*")
        );

        if (settings.classList.contains("active")) {
            for (let i = 0; i < settingsChildrens.length; i++) {
                if (e.target === settingsChildrens[i]) {
                    return settings.classList.add("active");
                } else {
                    settings.classList.remove("active");
                }
            }
        }
    });
    // ------------------x-------------x----------------

    // dark and light mode ---------------------------
    const darkModeClicked = () => {
        let body = document.querySelector("body");
        body.classList.add("dark");

        localStorage.setItem("theme-mode", "dark");
    };
    const lightModeClicked = () => {
        let body = document.querySelector("body");
        body.classList.remove("dark");

        localStorage.setItem("theme-mode", "light");
    };
    // ------------------x-------------x----------------

    // change color theme ---------------------------
    const changeThemeColor = (e) => {
        let body = document.querySelector("body");
        let data_color = e.target.getAttribute("data-color");

        if (body.classList.contains("dark")) {
            localStorage.setItem("theme-color", data_color);
            return (body.className = `dark ${data_color}`);
        }

        body.className = data_color;
        localStorage.setItem("theme-color", data_color);
    };
    // ------------------x-------------x----------------

    // change diraction / lang -------------------------
    // const changeDirAndLang = (e) => {
    //     let data_dir = e.target.getAttribute("data-dir");
    //     dispatch(changeDir(data_dir));
    // };
    // ------------------x-------------x----------------

    return (
        <div className="settings">
            <button className="setting-btn" onClick={btnSettingClicked}>
                <i
                    className="iconify"
                    data-icon="carbon:settings"
                    data-inline="false"
                ></i>
            </button>
            <div className="setting-content">
                <h3 className="title">
                    <i
                        className="iconify"
                        data-icon="carbon:settings-check"
                        data-inline="false"
                    ></i>{" "}
                    Setting
                </h3>
                <div className="item">
                    <h5 className="item-title">Dark & Light</h5>
                    <div className="item-content">
                        <span className="dark" onClick={darkModeClicked}>
                            <i
                                className="iconify"
                                data-icon="ic:outline-dark-mode"
                                data-inline="false"
                            ></i>
                        </span>
                        <span className="light" onClick={lightModeClicked}>
                            <i
                                className="iconify"
                                data-icon="ic:outline-light-mode"
                                data-inline="false"
                            ></i>
                        </span>
                    </div>
                </div>
                <div className="item">
                    <h5 className="item-title">colors</h5>
                    <div className="item-content">
                        <button
                            data-color="pink"
                            className="color pink"
                            onClick={changeThemeColor}
                        ></button>
                        <button
                            data-color="blue"
                            className="color blue"
                            onClick={changeThemeColor}
                        ></button>
                        <button
                            data-color="green"
                            className="color green"
                            onClick={changeThemeColor}
                        ></button>
                        <button
                            data-color="yellow"
                            className="color yellow"
                            onClick={changeThemeColor}
                        ></button>
                    </div>
                </div>
                {/* <div className="item">
                    <h5 className="item-title">RTL & LTR</h5>
                    <div className="item-content">
                        <Link
                            to={location.pathname}
                            className="btn-dir"
                            data-dir="LTR"
                            onClick={changeDirAndLang}
                        >
                            LTR
                        </Link>
                        <Link
                            to={location.pathname}
                            className="btn-dir"
                            data-dir="RTL"
                            onClick={changeDirAndLang}
                        >
                            RTL
                        </Link>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Settings;
